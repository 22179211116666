.pop_up_container {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  visibility: hidden;
}
.pop_up_container_active {
  visibility: visible;
}
.pop_up_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
}

.pop_up_width {
  background-color: #ffff;
  margin: auto;
  width: 30%;
  padding: 30px;
  z-index: 111;
  border-radius: 10px;
  position: relative;
}
.pop_up_width > p {
  font-size: 5px;
  margin-top: 10px;
}
.pop_up_title span {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-10px, 10px);
  font-size: 18px;
  cursor: pointer;
}

.pop_up_title p {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
}

.pop_up_width h3 {
  text-align: center;
  font-weight: bold;
  color: #7e52a0;
}

.pop_up_width {
  transform: scale(0);
  transition: 1s;
}

.pop_up_form_show {
  transform: scale(1);
}
.pop_up_form input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

.pop_up_form > div {
  margin-top: 10px;
}

.pop_up_form input[type="submit"] {
  margin-top: 20px;
  background-color: #7e52a0;
  color: #ffff;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 650px) {
  .pop_up_width {
    width: 70%;
  }
}
