.doctorItems {
  background: var(--primary);
  padding: 25px 0px;
}
.doctorItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.doctorItem {
  display: grid;
  grid-template-columns: 40% 50%;
  justify-content: space-between;
  background-color: #fff;

  border-radius: 35px 0px 35px 0px;
  padding: 35px;
  margin-bottom: 50px;
  width: calc(50% - 25px);

  transition: 0.3s all;
}
.doctorItem:hover {
  border-radius: 0px;
}
.doctorItem img {
  width: 100%;
}
.doctorItem_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.doctorItem_content h3 {
  font-size: 25px !important;
  text-transform: uppercase;
}
.doctorItem_content p {
  margin-bottom: 0;
}
.doctor_qualification {
}
.doctorItem_content > div p:nth-child(2) {
  margin-bottom: 10px;
}
.doctor_qualification {
  font-size: 14px !important;
  color: #535353cc;
  font-weight: 600 !important;
}

.doctorItem_content a {
  background-color: var(--primary);
  color: #fff;
  padding: 7px 21px;
  display: block;
  width: fit-content;
  margin-top: 25px;
}
.doctorItem_content a:hover {
  color: #ffff;
}

@media (max-width: 650px) {
  .doctorItem_content h3 {
    font-size: 18px !important;
    text-transform: uppercase;
  }
  .doctorItem {
    width: 100%;
  }
  .doctorItem_content p {
    font-size: 15px !important;
  }
  .doctorItem {
    border-radius: 15px 0px 15px 0px;
    padding: 15px;
    margin-bottom: 50px;
    grid-template-columns: 40% 55%;
  }
}
