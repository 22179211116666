body {
  margin: 0;
  font-family: omnes, sans-serif !important;
  font-style: normal !important;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --primary: #7e52a0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* font-family: omnes -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
.hand-link {
  color: #ea3288;
  font-size: 20px;
  font-weight: bold;
}
.text-blue {
  color: #7628b3;
}

h1 {
  font-size: 27px !important;
  /* text-transform:uppercase; */
}
h2 {
  font-size: 25px !important;
}
h3 {
  font-size: 19px !important;
}
p {
  font-size: 16px !important;
}

.heading1 {
  color: #332a86;
}
.heading2 {
  color: #332a86;
}
.btn {
  font-size: 17px !important;
}
#sitemap-buttons .btn-outline-danger {
  color: #ea3288;
  border-color: #ea3288;
}
#sitemap-buttons .btn-outline-danger:hover {
  background-color: #ea3288;
  color: #fff;
}
#sitemap-buttons .btn-danger {
  background-color: #ea3288;
  color: #fff;
}
.wFilter {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(194deg) brightness(101%) contrast(102%);
}
.pFilter {
  filter: brightness(0) saturate(100%) invert(32%) sepia(23%) saturate(1296%)
    hue-rotate(231deg) brightness(108%) contrast(85%);
}
