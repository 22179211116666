#contactUs-banner {
  background-image: linear-gradient(15deg, #7e52a0 40%, #ea3288 60%);
}
#contactUs-banner h2 {
  color: #fff;
}
#contactUs-banner .fa-map-marker-alt:hover {
  color: #7e52a0 !important;
  cursor: pointer;
}
#contactUs-banner .container > *,
#contactUs-banner.container-fluid > *,
#contactUs-banner.container-lg > *,
#contactUs-banner.container-md > *,
#contactUs-banner.container-sm > *,
#contactUs-banner.container-xl > *,
#contactUs-banner.container-xxl > * {
  padding-right: 5px !important;
}
#make-me-greeen {
  background-color: rgb(253, 247, 247);
}

#contactUs-title {
  font-size: 30px !important;
}

.related-links i:hover {
  cursor: pointer;
  background-color: #7e52a0 !important;
}
.related-links span {
  font-weight: bold;
}
.related-links span,
.related-links p {
  color: #ea3288;
}
.related-links .fa-map-marker-alt,
.related-links .fa-phone-alt,
.related-links .fa-envelope {
  background-color: #ef586f !important;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  margin-right: 20px;
  font-size: 22px;
}
.contact_form_wrapper {
  padding: 20px 0px 50px;
}
.contact_form_wrap {
  padding: 30px 0px;
}
.contact_form {
  width: 90%;
  background-color: #fff;
  margin: 0 auto !important;
  padding: 20px;
  border-radius: 10px;
}

.center_names ul {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
.center_names img {
  width: 100%;
}

.center_names li {
  width: 15%;
  display: flex;
  flex-direction: column;

  align-items: center;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  padding-bottom: 10px;
}
.center_active {
  border-bottom: 4px solid var(--primary) !important;
}
.center_names img {
  width: 90%;
  margin: 0 auto;
}

.center_map iframe {
  width: 100%;
  height: 300px;
}

@media (max-width: 650px) {
  .center_names li {
    width: 30%;
    margin-top: 30px;
  }
}
