#banner-logo-ovul {
  /* background-image: url(../assets/img/Treatments/treatments-banner/ovulationinduction.jpg) !important; */

  padding: 13% 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
}
#banner-logo-text {
  font-weight: 500;
  font-size: 42px;
  color: #fff !important;
}
.breadcrumb-item {
  font-size: 19px;
  --bs-breadcrumb-divider: ">";
}
/* .service #service-left-img {


.service #service-left-img {
  background-image: url(../assets/img/fertility/female_fertility.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  width: 100%;
  height: 340px;
} */
#service-left-img-ov img {
  width: 100%;
  height: 380px;
  border-radius: 10px;
  box-shadow: -1px 3px 7px 1px rgb(201, 197, 241);
}
.service #service-content-card,
/* #service-content2-card, */
#service-link-card,
.the-3-service-card {
  /* border: 2px solid red; */
  background-color: #f8f9fc;
  border-bottom: 1px solid red !important;
  /* width: 90%; */
  /* border: 1px solid rgb(188, 183, 236);
  border-radius: 8px;
  box-shadow: -1px 3px 7px 1px rgb(201, 197, 241);
  -webkit-transition: box-shadow ease-in-out 0.2s;
  transition: box-shadow ease-in-out 0.2s; */
  box-shadow: none !important;
  border: none !important;
}

.service #service-content-card:hover,
#service-content2-card:hover,
#service-link-card:hover,
.the-3-service-card:hover {
  box-shadow: none;
}
#service-content2-card {
  box-shadow: none !important;
  border: none !important;
}

.service #service-content-card hr {
  border: 0px solid rgb(51, 42, 134);
}

.service #service-link-card a {
  text-decoration: none;
  color: rgb(102, 100, 100);
  font-weight: 500;
}

.service #service-link-card li {
  list-style-type: none;
  line-height: 2rem;
  margin-left: -70px;
}

#scards .the-3-service-card {
  /* width: 90%; */
  height: auto;
}

#scards .the-3-service-card img {
  width: 250px;
}
@media only screen and (max-width: 400px) {
  #scards .the-3-service-card img {
    width: 200px;
  }
}

.accordion-item button:after {
  content: "-";
  font-size: 22px;
  font-weight: bold;
}

.accordion-item button.collapsed:after {
  content: "+";
  font-size: 22px;
  font-weight: bold;
}

.accordion-button::after {
  background: none !important;
}

button {
  box-shadow: none !important;
  background-color: #eee;
}
.fa-check-circle {
  color: #ea3288;
}
.service p {
  line-height: 1.8;
}

.testimonial_star img {
  width: 35% !important ;
}
.card-img-top {
  /* padding: 10px 0; */
}

#testimonial_branch .testimonials-item {
  border: none;
}

@media (max-width: 650px) {
  #testimonial_branch .testimonial_star img {
    width: 100% !important;
  }
}

.center_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.center_list p {
  width: 50%;
}
.center_360 iframe {
  width: 100%;
  height: 400px;
}

@media (max-width: 650px) {
}
