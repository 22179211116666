.dropdown-menu {
  background-color: #7e52a0 !important;
  color: #fff !important;
}
#mySidenav a {
  position: fixed;
  right: -140px;
  transition: 0.3s;
  padding: 15px;
  width: 160px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  border-radius: 0 5px 5px 0;
}

#mySidenav a:hover {
  right: 0;
}
.dropdown-item {
  color: #fff !important;
}
.dropdown-item Link:hover,
.dropdown-item a:hover {
  background-color: #7e52a0 !important;
  color: #fff !important;
}
.topbar-btn-color {
  background-color: #ea3288 !important;
  color: white !important;
}
.knagaroo-address {
  text-align: left !important;
}
.hidden {
  display: none;
}
#largeHideScreen a {
  text-decoration: none;
  color: #ea3288;
}
#largeHideScreen a:hover {
  text-decoration: underline;
}
#navDrop,
.offcanvas-body {
  background-color: #7e52a0;
}
#navDrop .nav-link,
.offcanvas-body .nav-link {
  color: #fff;
}
#small-buuton a {
  margin-left: 160%;
}
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
  color: rgb(253, 226, 71) !important;
  text-decoration: none;
  background: none !important;
}
.offcanvas-body {
  background-color: #fff;
  width: 100%;
  margin: 0;
  padding: 0 !important;
}
.header_buttons_wrapper {
  display: flex;
  justify-content: space-around;
}
#nav-sml-bar {
  background-color: #7e52a0 !important;
  padding-left: 20px;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  #big-scrn-visiblity,
  #ShowAtSmallestScrn {
    display: none;
  }
}

@media only screen and (max-width: 577px) {
  #nav-header-top .img-responsive {
    width: 80% !important;
    border-top: 1px solid red;
  }
  #small-screen-navbars {
    position: absolute;
    top: 5%;
    left: -2%;
    /* right: -80%; */
  }
  #secondToggle {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 281px) {
  #for-folded {
    float: left !important;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #fff !important;
}
#sml-scrn-img {
  width: 200px;
  height: auto;
}

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  margin-top: -5px;
  left: 100%;
}
.offcanvas-header {
  padding: 0 10px !important;
  padding-bottom: 20px !important;
}
#largeHideScreen .fa-map-marker-alt,
#largeHideScreen .fa-phone-alt,
#largeHideScreen .fa-envelope {
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  margin-right: 20px;
  font-size: 22px;
}
#hideMeAtBuuton {
  background-color: #7e52a0 !important;
  color: #fff;
}
.offcanvas-start {
  width: 325px !important;
}
.offcanvas-body {
  background: #7e52a0 !important;
}
marquee a {
  color: #ef586f;
}
#hideThisButton .fa-map-marker-alt {
  background-color: #ef586f;
  color: #fff;
  padding: 5px;
  font-size: 22px;
  border-radius: 50%;
}
#hidemePlzASAP span,
#hidemePlzASAP a {
  color: #ea3288 !important;
}
#nav-header-top .fa-map-marker-alt {
  color: #ea3288 !important;
}
#nav-header-top .address-link-style {
  color: #ea3288 !important;
}
#nav-header-top .fa-phone-alt {
  color: #7e52a0 !important;
}

.sticky-top-background {
  background-color: #fff;
}

.navbar_location a {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar_location img {
  width: 30px;
}
